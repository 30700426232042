.li {
  display: flex !important;
  justify-content: space-between;
}

.label {
  flex-basis: 20% !important;
  text-align: center !important;
}

.priority {
  flex-basis: 5% !important;
}

.status {
  flex-basis: 5% !important;
}

.title {
  flex-basis: 70% !important;
}

.pointer {
  cursor: pointer;
}

body {
  min-width: 100vw;
  min-height: calc(var(--vh, 1vh) * 100);
}

.app {
  padding: 1vw 1vh;
  padding: 1vw var(--vh, 1vh);
}

.header {
  padding: calc(var(--vh, 1vh) * 1) 2vw;
}

.error {
  display: block;
  padding: 2vh 2vw;
  text-align: center;
  color: lightcoral;
}

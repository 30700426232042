.error {
  display: block;
  padding: 2vh 2vw;
  text-align: center;
  color: red;
}

.button {
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

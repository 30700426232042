.box {
  cursor: pointer;
}

@media (max-width: 768px) {
  .box:nth-child(2n -1) {
    margin-right: 1%;
    width: 49%;
  }

  .box:nth-child(2n) {
    margin-left: 1%;
    width: 49%;
  }
}

@media (min-width: 768px) {
  .box:nth-child(3n -2) {
    margin: 0;
    width: 32.3%;
  }

  .box:nth-child(3n -1) {
    margin: 0 1%;
    width: 32.3%;
  }

  .box:nth-child(3n) {
    margin: 0;
    width: 32.3%;
  }
}
